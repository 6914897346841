.navbar {
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0 8px 6px -6px gray;
  --webkit-box-shadow: 0 8px 6px -6px gray;
  border-bottom: .8px solid rgb(0, 255, 119) !important;
  background-color: rgb(48, 48, 48) !important;
}

.nav-link {
  display: flex;
  align-self: flex-end;
  font-weight: 300 !important;
  color: white;
}
.nav-link:hover {
  color: wheat !important;
}
.active {
  border-bottom: 1.5px solid rgb(5, 204, 174);
  color: rgb(5, 204, 174) !important;
}
.navbar-brand:hover {
  color: white;
}

.navbar-brand h6 {
  padding-left: 12%;
  letter-spacing: 1px;
  font-family: 'Courier New', Courier, monospace;
  text-transform: none !important;
}

@media (min-width: 768px) {
  .iImg {
    width: 6%;
    border-radius: 20px;
  }

  .navbar-brand {
    font-weight: 700;
    font-family: 'roboto', sans-serif;
    letter-spacing: 2px;
    margin-left: 8px;
    color: white;
  }
}

@media (max-width: 767px) {
  .mnav {
    display: flex;
    flex-direction: row;
  }
  .iImg {
    width: 18%;
    border-radius: 20px;
    margin-right: 12px;
  }
  .navbar-brand {
    font-weight: 700;
    font-family: 'roboto', sans-serif;
    letter-spacing: 2px;
    margin-left: 4px;
    color: white;
  }
}