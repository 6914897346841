.mform-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1%;
    text-align: center;
}
.contact-t{
    font-size: 900;
    font-family: 'Times New Roman', Times, serif;
}
.mform-container form{
    width: 100%;
    margin-top: 2%;
}
.mform-container form textarea{
    height: 120px;
}
.inbox{
    width: 40%;
}
.btn{
    width: 16%;
}


@media (max-width: 767px) {
    .mform-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 1%;
        text-align: center;
        width: 100%;
    }
    .contact-t{
        font-size: 40px;
        font-family: 'Times New Roman', Times, serif;
    }
    .mform-container form{
        width: 100%;
        margin-top: 2%;
    }
    .mform-container form input{
        width: 88%;
    }
    .mform-container form textarea{
        width: 88%;
        height: 120px;
    }
    .btn{
        width: 16% !important;
    }
    .quot{
        margin-left: 2%;
        margin-right: 2%;
    }

    
}