.p-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.p-main h2 {
    margin-top: 2%;
    font-family: 'Times New Roman', Times, serif;
}


.p-main h4 {
    margin-bottom: 12px;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}
.ltitle {
    width: 100%;
    margin-left: 20%;
    text-align:left;
    font-family: 'Times New Roman', Times, serif;
}
.p-main input {
    width: 80%;
    margin-bottom: 3%;
    box-shadow: 0 8px 6px -4px lightskyblue;
}
.p-main textarea {
    width: 80%;
    height: 130px;
    margin-bottom: 3%;
    box-shadow: 0 8px 6px -4px lightskyblue;
}
.p-main select{
    width: 80%;
    height: 36px;
    margin-bottom: 3%;
    box-shadow: 0 8px 6px -4px lightskyblue;
}

.p-main button {
    background-color: lightskyblue;
    border: 1px solid lightskyblue;
}

.bbtn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.bbtn button{
    background-color: rgb(250, 118, 65);
    border: 1px solid rgb(250, 118, 65);
}
.a-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.icon {
    position: absolute;
    margin-top: 6px;
    transform: translateX(-20px);
    pointer-events: none;
}

@media(max-width:767px) {
    .p-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
    }
    
    .p-main h2 {
        margin-top: 2%;
        font-family: 'Times New Roman', Times, serif;
    font-size: 35px;
    }
    .p-main h4 {
        margin-bottom: 12px;
        text-align: center;
        font-family: 'Times New Roman', Times, serif;
    }
    .ltitle {
        width: 100%;
        margin-left: 10%;
        text-align:left;
        font-family: 'Times New Roman', Times, serif;
    }
    
    .p-main input {
        width: 80%;
        margin-bottom: 3%;
        box-shadow: 0 8px 6px -4px lightskyblue;
    }
    .p-main textarea {
        width: 80%;
        height:120px;
        margin-bottom: 3%;
        box-shadow: 0 8px 6px -4px lightskyblue;
    }
    
    .p-main button {
        background-color: lightskyblue;
        border: 1px solid lightskyblue;
    }
    
    .bbtn button {
        background-color: rgb(250, 118, 65);
        border: 1px solid rgb(250, 118, 65);
        width: 160px;
    }
    
    .sub-bt {
        display: flex;
        flex-direction: row;
    }    
    .a-main {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}