.mform-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1%;
    text-align: center;
}
.contact-t{
    margin-top: 2%;
    font-size: 900;
    font-family: 'Times New Roman', Times, serif;
}
.mform-container form{
    width: 100%;
    margin-top: 2%;
}
.inbox{
    width: 40%;
}
.log-btn{
    width: 16%;
    border-color: 1px solid rgb(5,204,174) !important;
    background-color: rgb(5,204,174) !important;
}
.lsign{
    margin-left: 35%;
}
.fpsd{
    margin-left: 30%;
}