.d-main{
    display: flex;
    flex-direction: column;
}
.d-main h3{
    margin-top:2.5%;
    margin-bottom: 1%;
    text-align: center;
    font-family:'Times New Roman', Times, serif;
}
.drow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.n-title{
    margin-bottom: 10%;
    text-align: center;
    font-family:'Times New Roman', Times, serif;
}

.n-description {
    font-size: 1.2rem;
    text-align: justify;
    line-height: 2;
    margin-bottom: 2;
}