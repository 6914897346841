.txt{
    color:white;
}

.footer{
    color:white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px;
    background-color: rgb(48, 48, 48) !important;
    box-shadow: 0 8px 6px -6px gray;
    --webkit-box-shadow:0 8px 6px -6px gray;
    border-top: 1px solid rgb(0, 255, 119) !important;
  }
  .footer a{
    text-decoration: none;
    color: white;
    padding: 6px;
  }
  .footer a:hover{
    color: wheat;
    border-bottom: 1px solid wheat;
  }