@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
/* font-family: 'Poppins', sans-serif; */

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,700;1,400&family=Poppins:wght@300;400&display=swap');
/* font-family: 'Playfair Display', serif;
font-family: 'Poppins', sans-serif; */


@media (min-width: 768px) {
    .my-container {
        margin-top: 0px;
        display: flex;
        align-items: center;
        min-height: 0vh;
        flex-direction: column;
        background-color: white;
        ;
    }
    .my-container img{
        width: 42%;
        height: 500px;
        object-fit:fill;
        border-radius: 20px;
        border-color: rgb(12, 223, 97);
        box-shadow: 0 8px 6px -4px rgb(12, 223, 97);
    }

    .home-subcontainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background-image: linear-gradient(0deg, #acee11 -10%, #acee11 100%);
    }
    .blog-post {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: 0 auto;
        border-bottom: 1.5px solid rgb(5, 204, 174);

    }

    .blog-post h6 {
        align-self: self-end;
        letter-spacing: 10;
    }

    .post-description {
        font-size: 1.2rem;
        text-align: justify;
        line-height: 1.8;
        margin-bottom: 1;
    }
    .myimg{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .ad{
        width: 60%;
    }
}


/* Styles for mobile web */
@media (max-width: 767px) {
    .my-container {
        display: flex;
        align-items: center;
        min-height: 0vh;
        flex-direction: column;
        background-color: white;
    }

    .home-subcontainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 0vh;
        background-image: linear-gradient(0deg, #acee11 -10%, #acee11 100%);
    }
    .my-container img{
        width: 80%;
        border-radius: 20px;
        box-shadow: 0 8px 6px -4px rgb(12, 223, 97);
    }

    .blog-post {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom: 1.5px solid rgb(5, 204, 174);

    }

    .blog-post img {
        max-width: 60%;
        margin-bottom: 1rem;
        border-radius: 32px;
    }

    .blog-post h6 {
        padding-right: 10%;
        align-self: self-end;
        letter-spacing: 10;
    }

    .post-description {
        width: 82%;
        font-size: 1.2rem;
        text-align: justify;
        line-height: 1.5;
        margin-bottom: 1;
    }
    .myimg{
        align-items: center;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .ad{
        width: 80%;
    }
}

/*------------Common-------------*/
.header-title {
    background-color: #253f4b;
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.header-title h4 {
    color: white;
    margin-top: 1%;
    font-family: 'Poppins', sans-serif;
    ;
}

.header-title h6 {
    color: wheat;
}


.blog-post a {
    margin-bottom: 2%;
    font-size: x-large;
    text-decoration: none;
    border-bottom: 1px solid white;
}

.blog-post a:hover {
    border-bottom: 1px solid orange;
}

.post-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

.mybody {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;
}

.btntc {
    text-align: center;
    color: white;
    background-color: rgb(5,204,174) !important;
    border-width: 0px;
    border-color: rgb(5,204,174);
    border-radius: 32px;
    
}
.btntc:hover{
    background-color: rgb(5,204,174) !important;
    color: wheat;
}

.read{
    background-color: transparent;
    border: none;
    font-weight: 600;
    color: lightseagreen;
}