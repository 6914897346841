.mform-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1%;
    text-align: center;
    width: 100%;
}
.contact-t{
    margin-top: 2%;
    font-size: 900;
    font-family: 'Times New Roman', Times, serif;
}
.mform-container form{
    width: 100%;
    margin-top: 1%;
}
.inbox{
    width: 38%;
}
.btn{
    background-color: rgb(5,204,174) !important;
    color: white !important;
    width: 160px !important;
    height: 40px !important;
}