
.slideshow {
  margin: 0 auto;
  margin-top: 0;
  overflow: hidden;
  width: 500px;
 
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display:inline-block;
  width: 100%;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 8px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: rgb(5,204,174)
}

.slide img{
  margin-top: 1%;
  width: 500px;
  height: 370px;
  border-radius: 20px;
  box-shadow: 0 8px 6px -4px rgb(106, 116, 122);
}
@media (max-width: 767px) {

.slideshow {
  margin: 0 auto;
  margin-top: 2%;
  overflow: hidden;
  max-width: 390px;
  
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
  margin-left: 8%;
}

.slide {
  display:inline-block;
  width: 100%;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  cursor: pointer;
  margin: 15px 8px 0px;
  background-color: #c4c4c4;
  
}

.slideshowDot.active {
  background-color: rgb(5,204,174);
}

.slide img{
  width: 80%;
  height: 300px;
  margin-left: 4%;
  border-radius: 20px;
  box-shadow: 0 8px 6px -4px lightskyblue;

}
}