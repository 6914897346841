.a-main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.a-main button{
    margin-top: 1%;
    width: 160px;
    height: 30px;
    margin-right: 1%;
    background-color: lightskyblue;
    border-radius: 20px;
    border: 1px solid lightskyblue;
}
@media(max-width:767px){
    .a-main{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-top: 1%;
    }
    .a-main button{
        margin-top: 1%;
        width: 160px;
        height: 30px;
        margin-right: 1%;
        background-color: lightskyblue;
        border-radius: 20px;
        border: 1px solid lightskyblue;
        color: black;
    }
}