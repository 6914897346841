.policy{
    text-align: justify;
    margin-left: 6%;
    margin-right: 6%;
}

@media (max-width: 767px){
    .policy{
        text-align: justify;
        margin-left: 6%;
        margin-right: 6%;
    }
}