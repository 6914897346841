.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.about img{
    width: 220px;
    height:220px;
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 200px;
    border-color:1px solid rgb(5,204,174);
    box-shadow: 0 8px 6px -2px rgb(78, 78, 78);
}
.about h3{
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
    font-size: 25px;
}
.about p{
    text-align:justify;
    margin-inline: 6%;
}
@media (max-width: 767px){
    .about{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .about img{
    width: 220px;
    height:220px;
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 200px;
    border-color:1px solid rgb(5,204,174);
    box-shadow: 0 8px 6px -2px rgb(78, 78, 78);
    }
    .about h3{
        font-family: 'Times New Roman', Times, serif;
        text-align: center;
        font-size: 35px;
    }
    .about p{
        text-align:justify;
        margin-inline: 6%;
    }
}